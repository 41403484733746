import React, { useContext } from "react"
import "./ClosePopUp.css"
import PopUpClose from "../elements/PopUpClose"
import { AppContext } from "../../../../../shared/Context/AppContext"

import AuthContext from "../../../../../shared/Context/authContext"

function ClosePopUp({ clickHandler }) {
  const { logout } = useContext(AuthContext)
  const { language } = useContext(AppContext)

  const closeConfirm = () => {
    logout()
  }

  const closePopUp = () => {
    clickHandler("ClosePopUp")
  }

  return (
    <div className="PopUp" id="ClosePopUp">
      <h2>{language === "NL" ? "Afsluiten?" : "Exit?"}</h2>
      <PopUpClose onClick={closePopUp} />
      <p>
        {language === "NL"
          ? "Weet je zeker dat je wilt afsluiten?"
          : "Are you sure you want to exit?"}
      </p>
      <div className="buttonsWrapper">
        <button className="popUpBnt" onClick={closeConfirm}>
          {language === "NL" ? "Ja" : "Yes"}
        </button>
        <button className="popUpBnt" onClick={closePopUp}>
          {language === "NL" ? "Nee" : "No"}
        </button>
      </div>
    </div>
  )
}

export default ClosePopUp
