import React from "react"
import "./PopUpClose.css"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function PopUpClose({ onClick }) {
  return (
    <button className="closeButton" onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  )
}

export default PopUpClose
