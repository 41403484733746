import React, { useContext, useState, useEffect } from "react"
import PopUpClose from "../elements/PopUpClose"
import { PanContext } from "../../../../../shared/Context/PanContext"
import { AppContext } from "../../../../../shared/Context/AppContext"

import "./OptionsPopUp.css"

function OptionsPopUp({ clickHandler }) {
  const { headerVisible, setHeaderVisible } = useContext(PanContext)
  const { language, setLanguage } = useContext(AppContext)
  const [fullScreen, setFullScreen] = useState(false)
  useEffect(() => {
    setFullScreen(getFullScreen() ? true : false)
  }, [])
  // const [muteSounds, setMuteSounds] = useState(false)
  const closePopUp = () => {
    clickHandler("OptionsPopUp")
  }

  // useEffect(() => {
  //   if (muteSounds) {
  //     console.log("mute!")
  //     const iframe = document.getElementsByTagName("iframe")[0]
  //     console.log(iframe)
  //     // iframe.contentDocument.getElementById("muted").checked = true
  //     // iframe.contentWindow.speaker[0].muted = true
  //     // iframe.contentWindow.speaker[1].muted = true
  //   }
  // }, [muteSounds])

  const getFullScreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullscreenElement ||
      document.msFullscreenElement
    )
  }

  const setFullScreenFcn = () => {
    if (getFullScreen()) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen()
      }
      setFullScreen(false)
    } else {
      var elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
      setFullScreen(true)
    }
  }

  const changeLanguage = (e) => {
    if (e.target.checked) {
      setLanguage("EN")
    } else {
      setLanguage("NL")
    }
  }

  return (
    <div className="PopUp" id="OptionsPopUp">
      <h2>{language === "NL" ? "Instellingen" : "Settings"}</h2>
      <PopUpClose onClick={closePopUp} />
      {/* <div className="optionWrapper">
        <div className="optDescr">Escape room in English</div>
        <div className="checkBoxWrapper">
          <input
            type="checkbox"
            id="switch2"
            defaultChecked={language === "EN"}
            onChange={(e) => {
              changeLanguage(e)
            }}
          />
          <label htmlFor="switch2">Toggle</label>
        </div>
      </div> */}
      {/* <div className="optionWrapper">
        <div className="optDescr">
          {language === "NL" ? "Informatie zichtbaar" : "Header visible"}
        </div>
        <div className="checkBoxWrapper">
          <input
            type="checkbox"
            id="switch1"
            defaultChecked={headerVisible}
            onChange={(e) => setHeaderVisible(e.target.checked)}
          />
          <label htmlFor="switch1">Toggle</label>
        </div>
      </div> */}
      <div className="optionWrapper">
        <div className="optDescr">
          {language === "NL"
            ? "Volledig scherm (voor de echte beleving)"
            : "Full screen"}
        </div>
        <div className="checkBoxWrapper">
          <input
            type="checkbox"
            id="switch3"
            defaultChecked={getFullScreen()}
            checked={fullScreen}
            onChange={() => setFullScreenFcn()}
          />
          <label htmlFor="switch3">Toggle</label>
        </div>
      </div>
      {/* <div className="optionWrapper">
        <div className="optDescr">
          Alle geluiden dempen <br />
          <span className="small">
            (Let op: voor sommige puzzels heb je geluid nodig)
          </span>
        </div>
        <div className="checkBoxWrapper">
          <input
            type="checkbox"
            id="switch3"
            defaultChecked={false}
            onChange={(e) => setMuteSounds(e.target.checked)}
          />
          <label htmlFor="switch3">Toggle</label>
        </div>
      </div> */}
    </div>
  )
}

export default OptionsPopUp
