import React, { useContext } from "react"
import "../Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/DropOff/uitleg3.jpg"

function Uitleg3() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Drop-off</span>
        </h1>
        <p>Remember to stay safe and have fun</p>
        <p>You're entering your last room now. Good luck!</p>
        <div className="progress-div">
          <span className="bold">Room 3/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Lastly, your look. What is Swashion?</p>
          <img src={Bike} className="inline-logo" />
        </div>

        <p>
          Do you know what the hardest thing is about learning to ride a bike?{" "}
          <i>The road</i>.
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg3
