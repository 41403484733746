import React, { useContext } from "react"
import "../Tussenpaginas.scss"

import { AppContext } from "../../../shared/Context/AppContext"

function Uitleg1() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">First Delivery</span>
        </h1>
        <div className="progress-div">
          <span className="bold">Room 1/3</span>
        </div>
        <p>Our first virtual room! Let's talk basics.</p>
        <p>In this room you will learn:</p>
        <ul>
          <li>What is Swapfiets</li>
          <li>About Swapfiets products</li>
          <li>What is a 'First Delivery'</li>
          <li>Our important system 'Hok'</li>
        </ul>
        <p>
          <span className="bold">Remember:</span> The room is clickable and you
          can change your perspective with the{" "}
          <span className="bold themakleur"> → </span>
          blue arrows on the ground
          <span className="bold themakleur"> ←</span>. Have a look around the
          store.
        </p>

        <p>
          Throughout the store you will
          <span className="themakleur">
            {" "}
            find the information you need to answer the
          </span>{" "}
          question under the{" "}
          <span className="bold themakleur">Continue {">>"}</span> button
          <span className="themakleur"> in order to move to the next room</span>
          !
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div className="welkom-picture-wrapper" id="uitleg1"></div>
    </div>
  )
}

export default Uitleg1
