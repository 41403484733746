import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Spinner,
} from "reactstrap"
import "./DashboardLogin.scss"

import logo from "../../components/Tussenpaginas/img/logo_1.png"

function Login({ setUserdata, setCityName }) {
  const [loading, setIsLoading] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleInputChange = (e) => {
    setError("")
    if (e.target.id === "input_username") {
      setUsername(e.target.value)
    }
    if (e.target.id === "input_password") {
      setPassword(e.target.value)
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    document.getElementById("submit-form").checkValidity()
    const loginUser = async () => {
      setIsLoading(true)
      setError("")
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/360env/auth/managerlogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: password,
              username: username,
              customer: "swapfiets",
            }),
          }
        )
        const responseData = await response.json()

        if (!response.ok) {
          setIsLoading(false)
          setError(responseData.errorMsg)
          throw new Error(responseData.errorMsg)
        }
        setUserdata(responseData.userData)
        setCityName(responseData.cityName)
        setIsLoading(false)
      } catch (err) {
        console.log(err.message)
        setIsLoading(false)
      }
    }
    loginUser()
  }

  return (
    <div className="login-wrapper" id="dashboard-login">
      <div className="Login">
        <img src={logo} />
        <Link to="/">
          <Button id="back-to-overview-btn">To onboarding flows</Button>
        </Link>
        <h2>Log in to the onboarding manager dashboard</h2>
        <Form className="form" onSubmit={submitForm} id="submit-form">
          <FormGroup>
            <Label for="exampleEmail">Username</Label>
            <Input
              type="text"
              name="text"
              id="input_username"
              placeholder="AmsterdamManager"
              value={username}
              onChange={handleInputChange}
              required
            />
            <FormText>Fill in the manager username of your city</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="input_password"
              placeholder="********"
              value={password}
              onChange={handleInputChange}
              required
            />
            <FormText>This is the manager password for your city</FormText>
          </FormGroup>

          <Button>
            <span>Log in</span>
          </Button>
          {loading ? <Spinner type="border" /> : ""}
        </Form>
        <div id="error-div-login">{error}</div>
      </div>
    </div>
  )
}

export default Login
