const TipsData = {
  pan01:
    "Be sure to click all the blue circles! Use the information on the blue circles answer the final question. Once you answer the question you can move to the next room!",
  pan02:
    "Be sure to click all the blue circles! Use the information on the blue circles answer the final question. Once you answer the question you can move to the next room!",
  pan03:
    "Be sure to click all the blue circles! Use the information on the blue circles answer the final question. Once you answer the question you can move to the next room!",
  pan04:
    "Be sure to click all the blue circles! Use the information on the blue circles answer the final question. Once you answer the question you can move to the next room!",
}
export default TipsData
