import React, { useContext } from "react"
import "../Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/OSRExchange/uitleg3.jpg"

function Uitleg3() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Onsite Repair (OSR).</span>
        </h1>
        <p>From the learnings in the previous room you should be able to:</p>
        <ul>
          <li>Replace the kickstand</li>
          <li>Change the grips</li>
        </ul>
        <div className="progress-div">
          <span className="bold">Room 3/4</span>
        </div>
        <p>In this next room we experience an OSR in a store, as well as:</p>
        <ul>
          <li>Shifts types (what's a crafter?!)</li>
          <li>How to change the saddle</li>
          <li>What is an OSR vs Exchange?</li>
        </ul>
        <div className="logo-left-inline">
          <p>
            <i>Sometimes some bikes are just ready for reTIREment…. hahahah</i>
          </p>
          <img src={Bike} className="inline-logo" />
        </div>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg3
