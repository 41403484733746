import React, { useState, useEffect, useContext } from "react"
import postscribe from "postscribe" // for script

// Import CSS
import "./Panorama.css"
import Controls from "./controls/Controls"
import MsgBox from "./helpers/MsgBox"

import { PanContext } from "../../shared/Context/PanContext"
import { AppContext } from "../../shared/Context/AppContext"
import AuthContext from "../../shared/Context/authContext"

function Panorama({
  dataTheasys,
  pathToTheasysLocal,
  PropPanId,
  curPart,
  kamer,
  finalPan,
  lockType,
  codeLength,
}) {
  const [freezePan, setFreezePan] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState("")
  const [headerVisible, setHeaderVisible] = useState(true)
  const { updateLocStore } = useContext(AuthContext)
  const { appProgress, name } = useContext(AppContext)

  let panStyle = {}
  freezePan
    ? (panStyle = { opacity: 0.5, pointerEvents: "none" })
    : (panStyle = { opacity: 1, pointerEvents: "auto" })

  useEffect(() => {
    updateLocStore(appProgress)
  })

  useEffect(() => {
    const enterNewPan = async () => {
      setIsLoading(true)
      try {
        const localUserData = JSON.parse(
          localStorage.getItem(`userData_${name}`)
        )
        const tokenExpirationDate = new Date(
          new Date().getTime() + 1000 * 60 * 60 * 48
        ) // expires in 48 hours

        localStorage.setItem(
          `userData_${name}`,
          JSON.stringify({
            token: localUserData.token,
            expiration: tokenExpirationDate.toISOString(),
            progress: appProgress,
          })
        )

        setIsLoading(false)
      } catch (err) {
        setError(`Error: ${err.message}`)
        console.log(err)
        setIsLoading(false)
      }
    }
    enterNewPan()

    postscribe(
      "#PanoramaIframe",
      `<script src="https://static.theasys.io/embed.js" data-theasys="${dataTheasys}" id="iframeID"></script>`
      // `<iframe src="${
      //   process.env.REACT_APP_INDEXPATH + pathToTheasysLocal
      // }" allowfullscreen="true" frameborder="0" scrolling="no" allow="vr;gyroscope;accelerometer" height="100%" style="width:100%;border:none;"></iframe>`
    )

    // To fix for weird bug that sometimes does not load Theasys
    let timer2 = setTimeout(() => {
      let panframe = document.getElementById("PanoramaIframe")
      if (panframe.children.length < 2) {
        window.location.reload()
      }
    }, 2000)

    return () => {
      clearTimeout(timer2)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <PanContext.Provider
      value={{
        panId: PropPanId,
        kamer: kamer,
        finalPan: finalPan,
        headerVisible: headerVisible,
        setHeaderVisible: setHeaderVisible,
        lockType: lockType,
        curPart: curPart,
        codeLength: codeLength,
      }}>
      <div className="PanoramaWrapper" id="PanoramaWrapper">
        {isLoading ? (
          <MsgBox msg="Loading" />
        ) : (
          <Controls setFreezePan={setFreezePan} />
        )}
        {error !== "" ? <MsgBox msg={error} /> : ""}
        <div
          className="importScriptWrapper"
          id="PanoramaIframe"
          style={panStyle}>
          {/* the script for Theasys is imported here via postscribe} */}
        </div>
      </div>
    </PanContext.Provider>
  )
}

export default Panorama
