import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"
import confetti from "canvas-confetti"

// import progress context
import AuthContext from "../../../shared/Context/authContext"

import Background from "../img/OSRExchange/einde.gif"

function Einde() {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    var duration = 15 * 1000
    var animationEnd = Date.now() + duration
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 50 * (timeLeft / duration)
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
    }, 250)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    logout()
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Magnificent!</span>
          <br />
          <span className="themakleur bold">Onsite Repair/ Exchange.</span>
        </h1>
        <div className="progress-div">
          <span className="bold">Finished Onsite Repair/Exchange</span>
        </div>
        <p>
          I hope you feel good about this flow!
          <br />
          You should now know:
        </p>
        <ul>
          <li>How to do a few OSRs (the bell, pedals)</li>
          <li>When a bike is good to leave the store (RTG)</li>
          <li>Who to call when you have a finance question</li>
          <li>Whether a bike is an OSR or an Exchange</li>
        </ul>

        <p>
          Get ready for a day of practice and then another flow!! Woohoo! We've
          got some more fun walkthroughs for you to learn from!
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Exit
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Einde
