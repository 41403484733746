import React, { useContext } from "react"
import "../Tussenpaginas.scss"

import { AppContext } from "../../../shared/Context/AppContext"

import Background from "../img/DropOff/uitleg1.jpg"

function Uitleg1() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Drop Off</span>
        </h1>
        <div className="progress-div">
          <span className="bold">Room 1/3</span>
        </div>
        <p>Welcome back!</p>
        <p>
          Today we are going through the end of the members life cycle with
          Swapfiets.
        </p>
        <ul>
          <li>Is the end really the end?</li>
          <li>Why is the tire blue?</li>
          <li>Have you ever participated in the Tour de France?</li>
        </ul>
        <p>
          <span className="bold">Remember:</span> The room is clickable and you
          can change your perspective with the{" "}
          <span className="bold themakleur"> → </span>
          blue arrows on the ground
          <span className="bold themakleur"> ←</span>. Have a look around the
          store.
        </p>

        <p>
          Throughout the store you will
          <span className="themakleur">
            {" "}
            find the information you need to answer the
          </span>{" "}
          question under the{" "}
          <span className="bold themakleur">Continue {">>"}</span> button
          <span className="themakleur"> in order to move to the next room</span>
          !
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg1
