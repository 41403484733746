import React, { useEffect, useContext } from "react"
import "./Header.css"
import SideUI from "../SideUI/SideUI"

import { PanContext } from "../../../../shared/Context/PanContext"
import { AppContext } from "../../../../shared/Context/AppContext"

import logo from "../../../Tussenpaginas/img/logo_1.png"

function Header({ clickHandler }) {
  const { curPart, headerVisible } = useContext(PanContext)
  const { numOfParts, displayName } = useContext(AppContext)

  // Update progress bar
  useEffect(() => {
    document.getElementById("progress-inside").style.width = `${
      ((curPart - 0.5) / numOfParts) * 100
    }%`
    document.getElementById("progress-inside-empty").style.width = `${
      (1 - (curPart - 0.5) / numOfParts) * 100
    }%`
  }, [])

  return (
    <div
      className="timerWrapper"
      style={headerVisible ? {} : { display: "none" }}>
      <img src={logo} id="banner-logo" />
      <div id="swap-pan-title">{displayName}</div>

      <div id="swap-pan-progress">
        <div>Progress:</div>
        <div id="progress-outside">
          <div id="progress-inside"></div>
          <div id="progress-inside-empty"></div>
        </div>
      </div>
      <SideUI clickHandler={clickHandler} />
    </div>
  )
}

export default Header
