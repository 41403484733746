import React, { useContext } from "react"
import "./Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../shared/Context/AppContext"

import logo from "./img/logo_1.png"
import House from "./img/House.png"
import Movie from "./img/Movie.png"
import Check from "./img/Check.png"
import Question from "./img/Question.png"
import Plane from "./img/Plane.png"

function Intro() {
  const { setAppProgress, displayName } = useContext(AppContext)

  const handleEnterClick = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina">
      <h1 className="fade-in fat black no-margin-bottom">
        Welcome to your Onboarding!
      </h1>
      <h1 className="fade-in fat">Virtual Rooms - {displayName}</h1>
      <p>
        Welcome to Swapfiets! Great to have you on board. To make your
        transition into your new role as smooth as possible, we designed a
        virtual store visit experience for you.
      </p>
      <p>
        The virtual store’s rooms are split into 4 flows, which correspond to
        your main tasks and responsibilities during your shift at the store.
        Don’t worry! Everything will become easier after your ‘visit’ in our
        virtual store.
      </p>
      <ul>
        <li>First Delivery</li>
        <li>Onsite Repair and Exchange</li>
        <li>Missing</li>
        <li>Drop Off</li>
      </ul>

      <div className="logo-left-inline">
        <img src={House} className="inline-logo" />
        <p>
          During your onboarding you will be able to experience what it is like
          to work in a Swapfiets store through these virtual rooms. Each flow
          will have 3 'rooms'.
        </p>
      </div>

      <p>
        The room is clickable and you can change your perspective with the
        <span className="bold themakleur"> → </span>
        blue arrows on the ground
        <span className="bold themakleur"> ←</span>. Have a look around the
        store. Throughout the store you will{" "}
        <span className="themakleur">
          find the information you need to move on to the next room
        </span>
        !
      </p>

      <div className="logo-left-inline">
        <img src={Movie} />
        <p>Each room has:</p>
      </div>
      <ul>
        <li>videos of member interactions [always at member],</li>
        <li>HOK slides [always at tablet],</li>
        <li>informative videos and </li>
        <li>
          games to learn about Swapfiets (products, service standards, cleaning
          policy, clothing policy, etc.). These are marked by clickable{" "}
          <span className="blue-o">O</span> blue circles{" "}
          <span className="blue-o">O</span>
        </li>
      </ul>

      <div className="logo-left-inline">
        <img src={Check} />
        <p>
          To <span className="themakleur">'complete'</span> each room you must
          answer the question under the{" "}
          <span className="bold themakleur">Continue {">>"}</span> button
          correctly. The question can be found at the top of the screen, next to
          your progress bar. If you don't know the answer to the question, keep
          looking around the room.{" "}
        </p>
      </div>

      <p className="themakleur">
        Open all the blue circles to learn about your role with Swapfiets.
      </p>

      <div className="logo-left-inline">
        <img src={Question} />
        <p>
          If you have questions about anything, ask your Store and Field Lead
          for guidance.
        </p>
      </div>

      <p>
        Once you've completed this room, be sure to practice what you've learned
        in the store or field and solidify those learnings!
      </p>

      <p>
        Each room has a 'landing page' click
        <span className="buttongreen bold"> continue </span> to begin!
      </p>

      <div className="logo-left-inline justify-center">
        <img src={Plane} className="inline-logo" />
        <p>What are you waiting for?! Go have fun!</p>
        <img src={Plane} className="inline-logo" />
      </div>

      <button
        className="doorgaanBtn"
        id="doorgaanBtn"
        onClick={handleEnterClick}>
        Continue
      </button>
      <img src={logo} id="logo_intro_page" alt="" />
    </div>
  )
}

export default Intro
