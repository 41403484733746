import React, { useState, useContext } from "react"
import "./MCPopUp.css"
import PopUpClose from "../elements/PopUpClose"

//import Context
import AuthContext from "../../../../../shared/Context/authContext"
import { PanContext } from "../../../../../shared/Context/PanContext"
import { AppContext } from "../../../../../shared/Context/AppContext"
import { MCQuestions, MCAnswers } from "./MCData/MCdata"

function MCPopUp({ clickHandler }) {
  const { panId, finalPan } = useContext(PanContext)
  const { name, appProgress, setAppProgress } = useContext(AppContext)
  const { token } = useContext(AuthContext)

  //   let [answer, setAnswer] = useState("")
  let [isLoading, setIsLoading] = useState(false)
  let [error, setError] = useState(false)

  // Handle submit all
  const handleSubmit = (answer2) => {
    setIsLoading(true)
    setError("")
    const checkanswers = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL +
            "/360env/progress/checkcombinationswap",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": token,
            },

            body: JSON.stringify({
              combination: `${answer2}`,
              escaperoom: name,
              panId: panId,
              progress: appProgress,
              final: finalPan,
            }),
          }
        )
        const responseData = await response.json()

        if (!response.ok) {
          throw new Error(responseData.errorMsg)
        }
        if (responseData.succes) {
          setAppProgress((p) => p + 1)
        } else {
          setError(
            "Oh no! Maybe look around the room. Watch the videos & play the games, then try again!"
          )
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        setError(err.message)
      }
    }
    if (answer2.length == 0) {
      setError("Fill in an answer")
      setIsLoading(false)
    } else {
      checkanswers()
    }
  }

  const closePopUp = () => {
    clickHandler("MCPopUp")
  }

  const handleMcClick = (e) => {
    handleSubmit(e.target.id)
  }

  const MCAnswerElements = MCAnswers[`${name}_${panId}`].map((answer) => {
    return (
      <button
        onClick={handleMcClick}
        className="mc-button popUpBnt"
        id={answer.id}>
        <span id={answer.id} className="answer-id-text">
          {answer.id}:
        </span>
        <span id={answer.id}>{answer.answer}</span>
      </button>
    )
  })

  // Create the elements
  return (
    <div className="MCPopUp PopUp" id="MCPopUp">
      <h2>Select the correct answer</h2>
      <PopUpClose onClick={closePopUp} />
      <div id="enter_password">
        <p>Have you clicked everything with a blue circle?</p> <br />
        <p>{MCQuestions[`${name}_${panId}`]}</p>
        {MCAnswerElements}
        <p className="errorDiv">{isLoading ? "Loading..." : error}</p>
      </div>
    </div>
  )
}

export default MCPopUp
