import React, { useContext } from "react"
import "../Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/OSRExchange/uitleg4.jpg"

function Uitleg3() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">
            Exchange {"&"} Onsite Repair (OSR).
          </span>
        </h1>
        <p>Do you think you know </p>
        <ul>
          <li>What a torque wrench is for?</li>
          <li>How to replace a bell?</li>
          <li>How to help a member with a flat tire?</li>
        </ul>
        <div className="progress-div">
          <span className="bold">Room 4/4</span>
        </div>
        <div className="logo-left-inline">
          <p>In this next room try to have some fun</p>
          <img src={Bike} className="inline-logo" />
        </div>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg3
