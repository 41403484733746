import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"
import confetti from "canvas-confetti"

// import progress context
import AuthContext from "../../../shared/Context/authContext"

function Einde() {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    var duration = 15 * 1000
    var animationEnd = Date.now() + duration
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 50 * (timeLeft / duration)
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
    }, 250)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    logout()
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Awesome!</span>
          <br />
          <span className="themakleur bold">First Delivery (FD).</span>
        </h1>
        <div className="progress-div">
          <span className="bold">Finished First Delivery</span>
        </div>
        <p>You've done it! You've completed your first flow!</p>
        <p>Now when new members walk in you are ready to help them! </p>
        <ul>
          <li>You can explain Swapfiets</li>
          <li>
            You can get the member set up and know when a bike will perform well
          </li>
          <li>You know the Terms and Conditions</li>
          <li>You understand the members experience</li>
          <li>You breathe Swapfiets values </li>
          <li>You know what we call the customer service desk</li>
        </ul>
        <p className="italic">And most importantly</p>
        <ul>
          <li>
            You know how to do it all in our systems! The steps in HOK are
            crucial
          </li>
        </ul>
        <p>
          Have fun practicing! And come back for the next flow! It will be an
          Onsite Repair/Exchange
        </p>
        <a
          href="https://app.happeo.com/pages/1f08ujece24fnsb397/FirstDelivery/1f3ldftu414r4igg6q?locale=no_language"
          target="_blank">
          <button className="doorgaanBtn" id="doorgaanBtn">
            Click here to go to the Happeo page on FDs
          </button>
        </a>

        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Exit
        </button>
      </div>
      <div className="welkom-picture-wrapper" id="einde"></div>
    </div>
  )
}

export default Einde
