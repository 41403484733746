import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Spinner,
} from "reactstrap"
import "./Login.scss"

// import progress context
import AuthContext from "../../shared/Context/authContext"
import { AppContext } from "../../shared/Context/AppContext"

import logo from "../Tussenpaginas/img/logo_1.png"

function Login() {
  const [loading, setIsLoading] = useState(false)
  const [fullName, setFullName] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const { name, displayName, setAppProgress } = useContext(AppContext)
  const { login } = useContext(AuthContext)

  const handleInputChange = (e) => {
    if (e.target.id === "input_full_name") {
      setFullName(e.target.value)
    }
    if (e.target.id === "input_password") {
      setPassword(e.target.value)
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    document.getElementById("submit-form").checkValidity()
    const loginUser = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/360env/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              envId: name,
              password: password,
              name: fullName,
              customer: "swapfiets",
            }),
          }
        )
        const responseData = await response.json()

        if (!response.ok) {
          setIsLoading(false)
          setError(responseData.errorMsg)
          throw new Error(responseData.errorMsg)
        }
        login(responseData.authToken)
        setIsLoading(false)
        setAppProgress((p) => p + 1)
      } catch (err) {
        console.log(err.message)
        setIsLoading(false)
      }
    }
    loginUser()
  }

  return (
    <div className="login-wrapper" id={`login-${name}`}>
      <div className="Login">
        <img src={logo} />
        <Link to="/">
          <Button id="back-to-overview-btn">Back to overview</Button>
        </Link>
        <h2>Sign in to the {displayName} onboarding</h2>
        <Form className="form" onSubmit={submitForm} id="submit-form">
          <FormGroup>
            <Label for="exampleEmail">Full Name</Label>
            <Input
              type="text"
              name="text"
              id="input_full_name"
              placeholder="e.g. Julia Roberts"
              value={fullName}
              onChange={handleInputChange}
              minLength={5}
              required
            />
            <FormText>
              Fill in your name so your manager can check if you've completed
              this onboarding flow
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">City acces password</Label>
            <Input
              type="password"
              name="password"
              id="input_password"
              placeholder="********"
              value={password}
              onChange={handleInputChange}
              required
            />
            <FormText>This password is provided by your manager</FormText>
          </FormGroup>

          <Button>
            <span>Log in</span>
          </Button>
          {loading ? <Spinner type="border" /> : ""}
        </Form>
        <div>{error}</div>
      </div>
    </div>
  )
}

export default Login
