import React from "react"
import { Link } from "react-router-dom"
import "./Homepage.css"

import logo from "../components/Tussenpaginas/img/logo_1.png"

import { Card } from "reactstrap"

function Homepage({ Flows }) {
  const Links = Flows.map((flow) => {
    return <Link to={`${flow.url}`}>{flow.name}</Link>
  })
  return (
    <div id="homepage-wrapper">
      <div id="homepage">
        <Card>
          <img src={logo} />
          <h1>Select the Onboarding training</h1>
          <div id="homepage-link-wrapper">{Links}</div>
          <div id="to-manager-dashboard">
            <Link to="./dashboard">Manager Dashboard</Link>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Homepage
