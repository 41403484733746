const MCAnswers = {
  swap_first_delivery_pan01: [
    {
      id: "A",
      answer: "a Power 7 Member",
    },
    {
      id: "B",
      answer: "a student",
    },
    {
      id: "C",
      answer: "Santa Claus with his sack of toys",
    },
    {
      id: "D",
      answer: "all of the above",
    },
  ],
  swap_first_delivery_pan02: [
    {
      id: "A",
      answer: "Safety Reasons",
    },
    {
      id: "B",
      answer: "Not common sense",
    },
    {
      id: "C",
      answer: "Not everyone knows how they work",
    },
    {
      id: "D",
      answer: "all of the above",
    },
  ],
  swap_first_delivery_pan03: [
    {
      id: "A",
      answer: "People already plan their leave",
    },
    {
      id: "B",
      answer: "Honest and transparent communication is important",
    },
    {
      id: "C",
      answer: "Our values: made simple",
    },
    {
      id: "D",
      answer: "Understanding of Expectations from the customer",
    },
    {
      id: "E",
      answer: "all of the above",
    },
    {
      id: "F",
      answer: "B, C, D",
    },
    {
      id: "G",
      answer: "B & C",
    },
  ],
  /// DROP OFF FLOW
  swap_drop_off_pan01: [
    {
      id: "A",
      answer: "15 days",
    },
    {
      id: "B",
      answer: "20 days",
    },
    {
      id: "C",
      answer: "25 days",
    },
    {
      id: "D",
      answer: "30 days",
    },
  ],
  swap_drop_off_pan02: [
    {
      id: "A",
      answer: "Yes",
    },
    {
      id: "B",
      answer: "No",
    },
  ],
  swap_drop_off_pan03: [
    {
      id: "A",
      answer: "Green",
    },
    {
      id: "B",
      answer: "Black",
    },
    {
      id: "C",
      answer: "Red",
    },
    {
      id: "D",
      answer: "Blue",
    },
    {
      id: "E",
      answer: "Purple",
    },
    {
      id: "F",
      answer: "Yellow",
    },
    {
      id: "G",
      answer: "White",
    },
  ],

  /// MISSING
  swap_missing_pan01: [
    {
      id: "A",
      answer: "€ 15,00 / kr. 115,00 /  £15.00",
    },
    {
      id: "B",
      answer: "€ 45,00 / kr. 345,00 /  £ 40.00",
    },
    {
      id: "C",
      answer: "€ 80,00 / kr. 600,00 /  £ 75.00",
    },
    {
      id: "D",
      answer: "€ 200,00 / kr. 1450,00 /  £ 165.00",
    },
  ],
  swap_missing_pan02: [
    {
      id: "A",
      answer: "1 week",
    },
    {
      id: "B",
      answer: "48 hours",
    },
    {
      id: "C",
      answer: "36 hours",
    },
    {
      id: "D",
      answer: "same day ",
    },
  ],
  swap_missing_pan03: [
    {
      id: "A",
      answer: "Made simple",
    },
    {
      id: "B",
      answer: "Exceptionally Good Looking bikes",
    },
    {
      id: "C",
      answer: "Grow Further",
    },
    {
      id: "D",
      answer: "Be Supportive",
    },
    {
      id: "E",
      answer: "Make it Happen",
    },
  ],

  /// OSR Exchange
  swap_osr_exchange_pan01: [
    {
      id: "A",
      answer: "OSR",
    },
    {
      id: "B",
      answer: "Exchange",
    },
  ],
  swap_osr_exchange_pan02: [
    {
      id: "A",
      answer: "It's the prettiest",
    },
    {
      id: "B",
      answer: "It ensures equal force around the bolt",
    },
    {
      id: "C",
      answer: "It's also blue like our tires",
    },
    {
      id: "D",
      answer: "It ensures the safety of our members",
    },
  ],
  swap_osr_exchange_pan03: [
    {
      id: "A",
      answer: "2 minutes",
    },
    {
      id: "B",
      answer: "5 minutes",
    },
    {
      id: "C",
      answer: "10 minutes",
    },
    {
      id: "D",
      answer: "15 minutes",
    },
    {
      id: "E",
      answer: "20 minutes",
    },
  ],
  swap_osr_exchange_pan04: [
    {
      id: "A",
      answer: "Utrecht",
    },
    {
      id: "B",
      answer: "Delft",
    },
    {
      id: "C",
      answer: "Amsterdam",
    },
    {
      id: "D",
      answer: "Brussels",
    },
    {
      id: "E",
      answer: "New York",
    },
  ],
}

const MCQuestions = {
  // First delivery
  swap_first_delivery_pan01: "Who would you sell a basket to?",
  swap_first_delivery_pan02:
    "Why are the Technical Instructions important to discuss?",
  swap_first_delivery_pan03:
    "Why is the Cancellation period important to mention?",

  // Drop-off
  swap_drop_off_pan01:
    "From the time the member cancels the subscription, how many days until they are no longer charged?",
  swap_drop_off_pan02:
    "The bike came back without a saddle, is there a Surcharge?",
  swap_drop_off_pan03: "What colour is our front tire?",

  // Missing
  swap_missing_pan01: "How much does a lost Deluxe 7 key costs our members?",
  swap_missing_pan02: "What is our service promise?",
  swap_missing_pan03: "Which of the following is not a Swapfiets value?",

  // OSR Exchange
  swap_osr_exchange_pan01: "The back tire is broken, OSR or Exchange?",
  swap_osr_exchange_pan02: "Why is the torque wrench an important tool?",
  swap_osr_exchange_pan03: "What is the max amount of time an OSR should take?",
  swap_osr_exchange_pan04: "What city did Swapfiets originate in?",
}

export { MCAnswers, MCQuestions }
