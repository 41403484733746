import { useState, useEffect, useCallback } from "react"

const useAuth = (setAppProgress, appProgress, name) => {
  const [token, setToken] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const login = useCallback(
    (tokenF) => {
      setWaiting(true)
      setToken(tokenF)
      const localUserData = JSON.parse(localStorage.getItem(`userData_${name}`))
      const tokenExpirationDate = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 48
      ) // expires in 3 hours
      if (localUserData) {
        setAppProgress(localUserData.progress)
      } else {
        localStorage.setItem(
          `userData_${name}`,
          JSON.stringify({
            token: tokenF,
            expiration: tokenExpirationDate.toISOString(),
            progress: appProgress,
          })
        )
      }
    },
    [setAppProgress]
  )

  const logout = useCallback(() => {
    setToken(null)
    setAppProgress(0)
    localStorage.removeItem(`userData_${name}`)
  }, [setAppProgress])

  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem(`userData_${name}`))
    if (localUserData && localUserData.token) {
      if (new Date(localUserData.expiration) < new Date()) {
        localStorage.removeItem(`userData_${name}`)
      } else {
        login(localUserData.token)
      }
    }
  }, [login])

  const updateLocStore = useCallback((inputProgress) => {
    const localUserData = JSON.parse(localStorage.getItem(`userData_${name}`))
    if (localUserData) {
      localStorage.setItem(
        `userData_${name}`,
        JSON.stringify({
          token: localUserData.token,
          expiration: localUserData.expiration,
          progress: inputProgress,
        })
      )
    }
  }, [])

  return {
    token,
    login,
    logout,
    waiting,
    updateLocStore,
  }
}

export default useAuth
