import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"

// import progress context
import AuthContext from "../../../shared/Context/authContext"
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

function Uitleg2() {
  const { updateLocStore } = useContext(AuthContext)
  const { appProgress, setAppProgress, language } = useContext(AppContext)

  useEffect(() => {
    updateLocStore(appProgress)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress(appProgress + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">First Delivery</span>
        </h1>
        <p>
          Nicely done! You figured out your first room.
          <ul>
            <li>
              Now you know Swapfiets started in 2014 from the idea to help bike
              owners. You know the differences between an Original {"&"} a Power
              7.
            </li>
            <li>What HOK is used for and what it looks like</li>
          </ul>
        </p>
        <div className="progress-div">
          <span className="bold">Room 2/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Cruising right along…</p>
          <img src={Bike} className="inline-logo" />
        </div>
        <p></p>
        <p>In this room you will learn:</p>
        <ul>
          <li>More about our Service Standards</li>
          <li>The Elevator Pitch</li>
          <li>What are Swapfiets Values</li>
        </ul>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          {language === "NL" ? "Doorgaan" : "Continue"}
        </button>
      </div>
      <div className="welkom-picture-wrapper" id="uitleg2"></div>
    </div>
  )
}

export default Uitleg2
