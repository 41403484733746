import React, { useState, useEffect } from "react"

import Header from "./Header/Header"
import SideUI from "./SideUI/SideUI"
import PopUpScreens from "./PopUpScreens/PopUpScreens"
import Tips from "./Tips/Tips"

function Controls({ setFreezePan }) {
  const [popUpOpen, setPopUpOpen] = useState(null)

  const closeAllPopUps = () => {
    const popUps = document.getElementsByClassName("PopUp")
    for (let i = 0; i < popUps.length; i++) {
      popUps[i].style.display = "none"
    }
  }

  const clickHandler = (connectId) => {
    const popup = document.getElementById(connectId)
    let popUpWasOpen = popup.style.display === "block" ? true : false
    closeAllPopUps()
    popup.style.display = popUpWasOpen ? "none" : "block"
    popUpWasOpen ? setPopUpOpen(null) : setPopUpOpen(connectId)
  }

  useEffect(() => {
    popUpOpen ? setFreezePan(true) : setFreezePan(false)
  }, [popUpOpen, setFreezePan])

  return (
    <>
      <PopUpScreens clickHandler={clickHandler} />
      <Header clickHandler={clickHandler} />
      <Tips />
    </>
  )
}

export default Controls
