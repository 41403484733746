import React, { Fragment, useContext } from "react"
import ClosePopUp from "./ClosePopUp/ClosePopUp"
import HelpPopUp from "./HelpPopUp/HelpPopUp"
import OptionsPopUp from "./OptionsPopUp/OptionsPopUp"
import MCPopUp from "./MCPopUp/MCPopUp"

import { PanContext } from "../../../../shared/Context/PanContext"

import "./PopUpScreens.css"

function PopUpScreens({ clickHandler }) {
  const { lockType } = useContext(PanContext)

  const LockTypePopUp = () => {
    switch (lockType) {
      case "mc":
        return <MCPopUp clickHandler={clickHandler} />
      default:
        console.log("LockType not found")
        return <MCPopUp clickHandler={clickHandler} />
    }
  }

  return (
    <Fragment>
      <ClosePopUp clickHandler={clickHandler} />
      <HelpPopUp clickHandler={clickHandler} />
      <OptionsPopUp clickHandler={clickHandler} />
      {LockTypePopUp()}
    </Fragment>
  )
}

export default PopUpScreens
