import React, { useContext } from "react"
import PopUpClose from "../elements/PopUpClose"
import { AppContext } from "../../../../../shared/Context/AppContext"

function HelpPopUp({ clickHandler }) {
  const { language } = useContext(AppContext)
  const closePopUp = () => {
    clickHandler("HelpPopUp")
  }
  return (
    <div className="PopUp" id="HelpPopUp">
      <h2>Help</h2>
      <PopUpClose onClick={closePopUp} />
      <h3>{language === "NL" ? "Ik kom er niet uit" : "I'm stuck"}</h3>
      <p>
        {language === "NL"
          ? "Heb je goed om je heen gekeken? Sommige clues zijn niet direct te zien."
          : "Did you take a good look around you? Some clues are not immediately visible. "}
      </p>
      <h3>
        {language === "NL" ? "Technische problemen" : "Technical problems"}
      </h3>
      <p>
        <a
          href="https://escapemystery.nl/faq"
          target="_blank"
          rel="noopener noreferrer">
          {language === "NL"
            ? "Lees hier onze FAQ-pagina"
            : "Find our FAQ-page here."}
        </a>{" "}
        {language === "NL" ? "voor veel gestelde vragen." : ""}
      </p>
      <br />
      <p>
        {language === "NL"
          ? "Als je na deze tips toch nog technische problemen ondervindt kun je de website opnieuw laden. Als dit niet werkt kun je afsluiten en opnieuw inloggen. Als de  storing hiermee niet verholpen is neem dan gerust contact op met helpdeks@escapemystery.nl. We doen ons best zo snel mogelijk bij je terug te komen, maar houd rekening met ongeveer 1 werkdag respons tijd."
          : "If you still experience technical problems after these tips, you can reload the website. If this doesn't work, you can exit and log in again. If this does not solve the problem, please contact helpdeks@escapemystery.nl. We will do our best to get back to you as soon as possible, but please allow about 1 working day response time."}
      </p>
      <div className="buttonsWrapper"></div>
    </div>
  )
}

export default HelpPopUp
