import React, { useState } from "react"
import { Table, Button, Input } from "reactstrap"

import logo from "../../components/Tussenpaginas/img/logo_1.png"

function DashboardTable({ userdata, cityName }) {
  const [searchValue, setSearchValue] = useState("")
  const [filteredData, setFilteredData] = useState(userdata)

  console.log(filteredData)
  const tableRows =
    filteredData &&
    filteredData.map((user, key) => {
      return (
        <tr key={key}>
          <td>{user.name}</td>
          <td>{user.environment}</td>
          <td>{Math.ceil(user.timelog.totalTime / 1000 / 60)} minutes</td>
          <td>
            {new Date(user.timelog.startTime).toLocaleDateString("en-GB")}
          </td>
        </tr>
      )
    })

  const handleSearch = (e) => {
    e.preventDefault()
    const results = userdata.filter((user) => {
      return user.name.toLowerCase().includes(searchValue.toLowerCase())
    })
    console.log(results)
    setFilteredData(results)
  }

  return (
    <div id="dashboard-wrapper">
      <img src={logo} id="logo" />
      <h1>Manager dashboard {cityName}</h1>
      <div id="dashboard-search">
        <label>Search by name</label>
        <form onSubmit={handleSearch}>
          <Input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button size="sm">Search</Button>
        </form>
      </div>
      <div id="dashboard-table-wrapper">
        <div id="dashboard-table-wrapper">
          <Table hover id="dashboard-table">
            <thead>
              <tr>
                <th>Name:</th>
                <th>Flow:</th>
                <th>Time to complete:</th>
                <th>Date of completion:</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                tableRows
              ) : (
                <tr>
                  <td colspan="4">No results</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default DashboardTable
