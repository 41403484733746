import React, { useContext } from "react"
import "./SideUI.css"
import SideItem from "./SideItem"
import { faTimes, faForward } from "@fortawesome/free-solid-svg-icons"
import { PanContext } from "../../../../shared/Context/PanContext"

function SideUI({ clickHandler }) {
  const { lockType } = useContext(PanContext)

  const LockItem = () => {
    switch (lockType) {
      case "mc":
        return (
          <SideItem
            name="Continue"
            icon={faForward}
            onClick={clickHandler}
            connectId={"MCPopUp"}
          />
        )

      default:
        return (
          <SideItem
            name="Continue"
            icon={faForward}
            onClick={clickHandler}
            connectId={"MCPopUp"}
          />
        )
    }
  }
  return (
    <div className="SideUI">
      {LockItem()}

      <SideItem
        name="Exit"
        icon={faTimes}
        onClick={clickHandler}
        connectId={"ClosePopUp"}
      />
    </div>
  )
}

export default SideUI
