// import Dependencies
import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Routes, Route } from "react-router-dom"

// Import general pages
import Homepage from "./general/Homepage"
import Dashboard from "./dashboard/Dashboard"

// Import different flows
import First_Delivery from "./flows/First_Delivery"
import OSR_Exchange from "./flows/OSR_Exchange"
import Missing from "./flows/Missing"
import DropOff from "./flows/Drop_Off"

// Import CSS
import "./main_css/App.css"
import "./main_css/Resets.css"

function App() {
  const Flows = [
    {
      id: "flow_1",
      name: "First Delivery",
      url: "first_delivery",
      component: <First_Delivery />,
    },
    {
      id: "flow_2",
      name: "OSR/Exchange",
      url: "osr_exchange",
      component: <OSR_Exchange />,
    },
    {
      id: "flow_3",
      name: "Missing",
      url: "missing",
      component: <Missing />,
    },
    {
      id: "flow_4",
      name: "Drop Off",
      url: "drop_off",
      component: <DropOff />,
    },
  ]

  const FlowRoutes = Flows.map((flow) => {
    return <Route path={`/${flow.url}`} element={flow.component} />
  })

  return (
    <Routes>
      <Route path="/" element={<Homepage Flows={Flows} />} />
      <Route path="/dashboard" element={<Dashboard Flows={Flows} />} />
      {FlowRoutes}
    </Routes>
  )
}

export default App
