import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"

// import progress context
import AuthContext from "../../../shared/Context/authContext"
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/DropOff/uitleg2.png"

function Uitleg2() {
  const { updateLocStore } = useContext(AuthContext)
  const { appProgress, setAppProgress, language } = useContext(AppContext)

  useEffect(() => {
    updateLocStore(appProgress)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress(appProgress + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Drop-off</span>
        </h1>
        <p>Think you can tell a few stories about Swapfiets now?</p>
        <ul>
          <li>Have you heard about Vittoria tires?</li>
          <li>
            The 'Jumbo Visma' team in the Tour de France never looked so good.
          </li>
        </ul>

        <div className="progress-div">
          <span className="bold">Room 2/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Let's talk safety and cleanliness</p>
          <img src={Bike} className="inline-logo" />
        </div>
        <p>
          I blew a tire on my way home and had to push my bike home.{" "}
          <i>It was a drag</i>.
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          {language === "NL" ? "Doorgaan" : "Continue"}
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg2
