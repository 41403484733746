import React, { useState, useEffect } from "react"

// Import Flow components
import Uitleg1 from "../components/Tussenpaginas/OSRExchange/Uitleg1"
import Uitleg2 from "../components/Tussenpaginas/OSRExchange/Uitleg2"
import Uitleg3 from "../components/Tussenpaginas/OSRExchange/Uitleg3"
import Uitleg4 from "../components/Tussenpaginas/OSRExchange/Uitleg4"
import Einde from "../components/Tussenpaginas/OSRExchange/Einde"

// Import general Flow coponents
import Login from "../components/Login/Login"
import Panorama from "../components/Panorama/Panorama"
import Intro from "../components/Tussenpaginas/Intro_General"

// Import custon hooks for authorization
import useAuth from "../shared/Hooks/authHook"

import { AppContext } from "../shared/Context/AppContext"
import AuthContext from "../shared/Context/authContext"

function First_Delivery() {
  const name = "swap_osr_exchange"
  const numOfParts = 4
  const [appProgress, setAppProgress] = useState(0)
  const displayName = "OSR/Exchange"

  const { token, login, logout, updateLocStore } = useAuth(
    setAppProgress,
    appProgress,
    name
  )

  useEffect(() => {
    updateLocStore(appProgress)
  }, [appProgress])

  const components = [
    <Login />,
    <Intro />,
    <Uitleg1 />,
    <Panorama
      dataTheasys={"8ZY5IoaNfrWbDuMXtMl9gzkYGJaIgV"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan01"
      curPart={1}
      kamer="Swapstore Utrecht"
      finalPan={false}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Uitleg2 />,
    <Panorama
      dataTheasys={"8ZY5IoaNfrWbDuMYsCkS6gxeyb8vj9"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan02"
      curPart={2}
      kamer="Swapstore Utrecht"
      finalPan={false}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Uitleg3 />,
    <Panorama
      dataTheasys={"8ZY5IoaNfrWbDuMVDMHj0QbPQv9fHZ"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan03"
      curPart={3}
      kamer="Swapstore Utrecht"
      finalPan={false}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Uitleg4 />,
    <Panorama
      dataTheasys={"8ZY5IoaNfrWbDuMv3xg0gaqI4sn1Tg"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan04"
      curPart={4}
      kamer="Swapstore Utrecht"
      finalPan={true}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Einde />,
  ]

  return (
    <AppContext.Provider
      value={{
        numOfParts: numOfParts,
        name: name,
        displayName: displayName,
        appProgress: appProgress,
        setAppProgress: setAppProgress,
      }}>
      <AuthContext.Provider
        value={{
          token: token,
          login: login,
          logout: logout,
          updateLocStore: updateLocStore,
          setAppProgress: setAppProgress,
        }}>
        <div className="EscapeRoomBody">{components[appProgress]}</div>
      </AuthContext.Provider>
    </AppContext.Provider>
  )
}

export default First_Delivery
