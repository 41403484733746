import React, { useState, useEffect } from "react"
import "./Dashboard.scss"

import DashboardLogin from "./components/DashboardLogin"
import DashboardTable from "./components/DashboardTable"

function Dashboard({ Flows }) {
  const [userdata, setUserdata] = useState()
  const [cityName, setCityName] = useState("")

  useEffect(() => {
    console.log(userdata)
  }, [userdata])

  return userdata ? (
    <DashboardTable userdata={userdata} cityName={cityName} />
  ) : (
    <DashboardLogin setUserdata={setUserdata} setCityName={setCityName} />
  )
}

export default Dashboard
