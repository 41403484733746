import React, { useState, useEffect } from "react"

// Import Flow components
import Uitleg1 from "../components/Tussenpaginas/DropOff/Uitleg1"
import Uitleg2 from "../components/Tussenpaginas/DropOff/Uitleg2"
import Uitleg3 from "../components/Tussenpaginas/DropOff/Uitleg3"
import Einde from "../components/Tussenpaginas/DropOff/Einde"

// Import general Flow coponents
import Login from "../components/Login/Login"
import Panorama from "../components/Panorama/Panorama"
import Intro from "../components/Tussenpaginas/Intro_General"

// Import custon hooks for authorization
import useAuth from "../shared/Hooks/authHook"

import { AppContext } from "../shared/Context/AppContext"
import AuthContext from "../shared/Context/authContext"

function First_Delivery() {
  const name = "swap_drop_off"
  const numOfParts = 3
  const [appProgress, setAppProgress] = useState(0)
  const displayName = "Drop Off"

  const { token, login, logout, updateLocStore } = useAuth(
    setAppProgress,
    appProgress,
    name
  )

  useEffect(() => {
    updateLocStore(appProgress)
  }, [appProgress])

  const components = [
    <Login />,
    <Intro />,
    <Uitleg1 />,
    <Panorama
      dataTheasys={"hcRePqVjHDhDBS5n7iXYXCNhRiQlRE"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan01"
      curPart={1}
      kamer="Swapstore Utrecht"
      finalPan={false}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Uitleg2 />,
    <Panorama
      dataTheasys={"hcRePqVjHDhDBS5gizeg5972TIjABQ"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan02"
      curPart={2}
      kamer="Swapstore Utrecht"
      finalPan={false}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Uitleg3 />,
    <Panorama
      dataTheasys={"hcRePqVjHDhDBS5iMExyG0L45Eoiv0"}
      pathToTheasysLocal={"/pan03files/index.html"}
      PropPanId="pan03"
      curPart={3}
      kamer="Swapstore Utrecht"
      finalPan={true}
      lockType="mc" // lock, panel, form
      codeLength={1}
    />,
    <Einde />,
  ]

  return (
    <AppContext.Provider
      value={{
        numOfParts: numOfParts,
        name: name,
        displayName: displayName,
        appProgress: appProgress,
        setAppProgress: setAppProgress,
      }}>
      <AuthContext.Provider
        value={{
          token: token,
          login: login,
          logout: logout,
          updateLocStore: updateLocStore,
          setAppProgress: setAppProgress,
        }}>
        <div className="EscapeRoomBody">{components[appProgress]}</div>
      </AuthContext.Provider>
    </AppContext.Provider>
  )
}

export default First_Delivery
