import React, { useContext } from "react"
import "../Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

function Uitleg3() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">First Delivery</span>
        </h1>
        <p>Nicely done! You are getting the hang of a First Delivery.</p>
        <ul>
          <li>Now you know what good service looks like at Swapfiets</li>
          <li>You know our values:</li>
        </ul>

        <div className="themakleur bold flex-custom-1-wrapper">
          <div className="flex-custom-1">
            <div>be supportive</div>
            <div>make it happen</div>
          </div>
          <div className="flex-custom-1">
            <div>grow further</div>
            <div>made simple</div>
          </div>
        </div>
        <ul>
          <li>How to describe Swapfiets in 30 seconds (Elevator Pitch)</li>
        </ul>
        <div className="progress-div">
          <span className="bold">Room 3/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Hope you aren't two tired…</p>
          <img src={Bike} className="inline-logo" />
          <p>because we have one more room!</p>
        </div>

        <ul>
          <li>You'll see our most common tools</li>
          <li>Swapdesk.. What does that mean?</li>
          <li>And you'll finish off the First Delivery!</li>
        </ul>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div className="welkom-picture-wrapper" id="uitleg3"></div>
    </div>
  )
}

export default Uitleg3
