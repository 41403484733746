import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"

// import progress context
import AuthContext from "../../../shared/Context/authContext"
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/OSRExchange/uitleg2.jpg"

function Uitleg2() {
  const { updateLocStore } = useContext(AuthContext)
  const { appProgress, setAppProgress, language } = useContext(AppContext)

  useEffect(() => {
    updateLocStore(appProgress)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress(appProgress + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Exchange.</span>
        </h1>
        <p>
          From that room you should be able to answer the following questions:
        </p>
        <ul>
          <li>What different subscriptions we have</li>
          <li>How to check a bike for a safe ride</li>
        </ul>

        <div className="progress-div">
          <span className="bold">Room 2/4</span>
        </div>

        <p>
          In this next room you will see our Swapper triage the member's
          bicycle.
        </p>

        <p>
          Triage is an important part of the Exchange flow. We have to be able
          to determine if a bike is in need of a quick 10 minute repair, or if
          the bicycle will need a full exchange.
        </p>

        <p>In this room you will see:</p>
        <ul>
          <li>Types of basic (L1) repairs</li>
          <li>Different bike sizes</li>
          <li>What an Exchange flow looks like</li>
        </ul>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          {language === "NL" ? "Doorgaan" : "Continue"}
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg2
