import React, { useContext } from "react"
import "../Tussenpaginas.scss"

// import progress context
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/Missing/uitleg3.jpg"

function Uitleg3() {
  const { setAppProgress } = useContext(AppContext)

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress((p) => p + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Missing</span>
        </h1>
        <p>Think you could put on a basket?</p>
        <p>
          Could you help me?
          <br />
          My bike chain went rusty. Then my whole bicycle fell apart. It was a
          chain reaction. haha
        </p>
        <div className="progress-div">
          <span className="bold">Room 3/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Time for the last room in this flow</p>
        </div>

        <ul>
          <li>We see the Service Standards again</li>
          <li>Hear a service story</li>
          <li>
            And see how to help a member who doesn't want to replace their
            missing bike
          </li>
        </ul>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Continue
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg3
