import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"
import confetti from "canvas-confetti"

// import progress context
import AuthContext from "../../../shared/Context/authContext"

import Background from "../img/DropOff/einde.gif"

function Einde() {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    var duration = 15 * 1000
    var animationEnd = Date.now() + duration
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 50 * (timeLeft / duration)
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          colors: ["#05aae1", "#ffffff"],
        })
      )
    }, 250)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    logout()
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Fantastic!</span>
          <br />
          <span className="themakleur bold">Drop-off.</span>
        </h1>
        <div className="progress-div">
          <span className="bold">Finished the Drop-off Flow!</span>
        </div>
        <p>
          You should now be able to tell your friends all about Swapfiets!
          <br />
          <i>
            This image is from when Amsterdam got their 50,000 customer!!
            #growfurther
          </i>
        </p>
        <p>
          Do you understand how to use our HOK/Smooth systems to keep our
          information accurate?
        </p>

        <p>
          Are you feeling confident about working on the floor?!
          <br />
          You look like a professional! Now go be a stellar Swapper! <br />
          And don't forget to have fun
        </p>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          Exit
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Einde
