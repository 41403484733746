import React, { useEffect, useContext, useState } from "react"
import "./Tips.scss"
import TipsData from "./TipsData"

import { PanContext } from "../../../../shared/Context/PanContext"

function Tips() {
  const { panId } = useContext(PanContext)
  const [tipContentEN, setTipContentEN] = useState("")
  const [closed, setClosed] = useState(false)

  const handleCloseTip = () => {
    document.getElementById("tipdiv").classList.add("hiddenTip")
    setClosed(true)
  }

  // Show the tip only after 5 seconds
  useEffect(() => {
    const timer1 = setInterval(() => {
      if (!closed) {
        document.getElementById("tipdiv").classList.remove("hiddenTip")
        setTipContentEN(TipsData[panId])
      }
    }, 5000)

    return () => {
      clearInterval(timer1)
    }
  }, [closed])

  return (
    <div className="tip-wrapper">
      <div className="tip hiddenTip" id="tipdiv">
        <div className="tipHeader">
          Tip
          <div className="closeTip" onClick={handleCloseTip}>
            x
          </div>
        </div>
        <div className="tipContent">{tipContentEN}</div>
      </div>
    </div>
  )
}

export default Tips
