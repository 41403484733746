import React from "react"
import "./SideItem.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function SideItem({ onClick, name, icon, connectId }) {
  return (
    <button className="SideItem" onClick={() => onClick(connectId)}>
      <div className="textUI">{name}</div>
      <div className="iconFA">
        <FontAwesomeIcon icon={icon} />
        <span className="textsmallUI">{name}</span>
      </div>
    </button>
  )
}

export default SideItem
