import React, { useContext, useEffect } from "react"
import "../Tussenpaginas.scss"

// import progress context
import AuthContext from "../../../shared/Context/authContext"
import { AppContext } from "../../../shared/Context/AppContext"

import Bike from "../img/Bike.png"

import Background from "../img/Missing/uitleg2.jpg"

function Uitleg2() {
  const { updateLocStore } = useContext(AuthContext)
  const { appProgress, setAppProgress, language } = useContext(AppContext)

  useEffect(() => {
    updateLocStore(appProgress)
  })

  const handleClickContinue = (e) => {
    e.preventDefault()
    setAppProgress(appProgress + 1)
  }

  return (
    <div className="Tussenpagina" id="welkom-pagina">
      <div>
        <h1>
          <span className="black bold">Swapfiets</span>
          <br />
          <span className="themakleur bold">Missing</span>
        </h1>
        <p>
          That member did not seem happy about their missing bike… hopefully we
          can help
        </p>
        <ul>
          <li>Can you de-escalate a situation?</li>
          <li>If the key is lost, do you know what to do?</li>
        </ul>

        <div className="progress-div">
          <span className="bold">Room 2/3</span>
        </div>
        <div className="logo-left-inline">
          <p>Let's replace that product for them!</p>
          <img src={Bike} className="inline-logo" />
        </div>
        <p> In this room you will learn:</p>

        <ul>
          <li>How to attach a basket</li>
          <li>How to replace a product for the member</li>
          <li>How to upgrade or downgrade a subscription</li>
        </ul>
        <button
          className="doorgaanBtn"
          id="doorgaanBtn"
          onClick={handleClickContinue}>
          {language === "NL" ? "Doorgaan" : "Continue"}
        </button>
      </div>
      <div
        className="welkom-picture-wrapper"
        style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  )
}

export default Uitleg2
